const Types = {
    SET_NAME: "SET_NAME",
    SET_SURNAME: "SET_SURNAME",
    SET_EMAIL: "SET_EMAIL"
};

export const setName = (name) => ({ type: Types.SET_NAME, payload: name });

export const setSurname = (surname) => ({ type: Types.SET_SURNAME, payload: surname });

export const setEmail = (email) => ({ type: Types.SET_EMAIL, payload: email });

export default {
    setName,
    setSurname,
    setEmail,
    Types
};
