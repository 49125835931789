import React from "react";
import { Link, useLocation, withRouter } from "react-router-dom";
import "./style.css";
import Header from "../Header";
import Video from "../Video";

const breakoutStyle = {
    height: "100%",
    width: "100%",
    border: "0px"
};

function BreakoutRoom()
{
    const location = useLocation();
    let title = "";
    let pathName = "";

    if (location.pathname === "/BreakoutRoom1")
    {
        title = "Breakout Room 1";
        pathName = "https://meet.jit.si/hope_rides_with_us_1#config.disableDeepLinking=true";
    }
    else if (location.pathname === "/BreakoutRoom2")
    {
        title = "Breakout Room 2";
        pathName = "https://meet.jit.si/hope_rides_with_us_2#config.disableDeepLinking=true";
    }
    else
    {
        title = "Breakout Room 3";
        pathName = "https://meet.jit.si/hope_rides_with_us_3#config.disableDeepLinking=true";
    }

    return (
        <div className="BreakoutRoom">
            <Header />
            <iframe
                allow="camera; microphone; display-capture"
                title={title}
                src={pathName}
                style={breakoutStyle}
            />

            <Link to="/" className="BreakoutRoom_Link">
                <span className="BreakoutRoom_Text">Return Home</span>
            </Link>
        </div>
    );
}

/*
const BreakoutRoom = ({ whichRoom }) => (
    <div className="BreakoutRoom">
        <Header />
        <iframe
            allow="camera; microphone; display-capture"
            title="Breakout Room 1"
            src={whichRoom}
            style={breakoutStyle}
        />

        <Link to="/" className="BreakoutRoom_Link">
            <span className="BreakoutRoom_Text">Return Home</span>
        </Link>
    </div>
);
*/
export default withRouter(BreakoutRoom);
