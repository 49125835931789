import React from "react";
import { Link, BrowserRouter, Route, Switch, withRouter } from "react-router-dom";
import "./style.css";

function stopIframeVideo()
{
    const iframe = document.getElementsByTagName("iframe")[0].contentWindow;

    if (iframe)
    {
        console.log("video paused");
        iframe.postMessage("{'method':'pause'}", "*");
        /*
        const domain = "meet.jit.si";
        const options = {
            roomName: "hope_rides_with_us_1",
            displayName: "Hope Rides With Us #1",
            width: document.getElementsByClassName("Video")[0].clientWidth,
            height: document.getElementsByClassName("Video")[0].clientHeight,
            // parentNode: document.getElementsByClassName("Video")[0]
            parentNode: document.querySelector("#jitsi-iframe")
        };
        document.getElementsByClassName("Video")[0].hidden = true;
        const api = new window.JitsiMeetExternalAPI(domain, options);
        // document.getElementsByTagName("iframe")[0].hidden = true;
         */
    }
}

const Buttons = () => (
    <div className="Buttons">
        <Link
            to="/BreakoutRoom1"
            className="Buttons_Link"
            onClick={stopIframeVideo}
        >
            <span className="Buttons_Text">CTF 101:</span>
            <span className="Buttons_Text">New Rider</span>
        </Link>

        <Link
            to="/BreakoutRoom2"
            className="Buttons_Link"
            onClick={stopIframeVideo}
        >
            <span className="Buttons_Text">Alumni</span>
            <span className="Buttons_Text">Riders</span>
        </Link>

        <Link
            to="/BreakoutRoom3"
            className="Buttons_Link"
            onClick={stopIframeVideo}
        >
            <span className="Buttons_TextSmall Buttons_Text">Cycling</span>
            <span className="Buttons_TextSmall Buttons_Text">101</span>
        </Link>
    </div>
);

export default withRouter(Buttons);
