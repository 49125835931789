import React from "react";
// import ScrollBar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import Header from "../Header";
import Video from "../Video";
import Buttons from "../Buttons";
import "./style.css";

const HomePage = () => (
    <div className="HomePage">
        <div component="div" className="HomePage_Content">
            <Header />
            <span className="HomePage_TextBig">Hope Rides With Us</span>
            <span className="HomePage_TextSmall">September 10, 2022</span>

            <Video />
            <div id="jitsi-iframe" />
            <span className="HomePage_TextCaps HomePage_TextMedium">BREAKOUT ROOMS — CLICK BELOW AFTER</span>
            <span className="HomePage_TextCaps HomePage_TextMedium">THE PRESENTATION ABOVE IS COMPLETED</span>

            <Buttons />

            <a
                target="_blank"
                rel="noreferrer"
                href="https://www.rideclosertofree.org/register"
                className="HomePage_TextRegister HomePage_TextMedium"
            >
                Register Today!
            </a>
        </div>
    </div>
);

export default HomePage;
