import ACTIONS from "./actions";

const defaultState = {
    name: "",
    surname: "",
    email: ""
};

const globalReducer = (state = defaultState, { type, payload }) =>
{
    switch (type)
    {
        case ACTIONS.Types.SET_NAME:
            return { ...state, name: payload };

        case ACTIONS.Types.SET_SURNAME:
            return { ...state, surname: payload };

        case ACTIONS.Types.SET_EMAIL:
            return { ...state, email: payload };

        default:
            return state;
    }
};

export default globalReducer;
