import React from "react";
import Routes from "../Routes";
import "./style.css";

const App = () => (
    <div className="App">
        <div className="App__Content">
            <Routes />
        </div>
    </div>
);
export default App;
