import React from "react";
import { Route, Switch } from "react-router-dom";
import HomePage from "../Content/HomePage";
import BreakoutRoom from "../Content/BreakoutRoom";

export default () => (
    <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/BreakoutRoom1" exact component={BreakoutRoom} />
        <Route path="/BreakoutRoom2" exact component={BreakoutRoom} />
        <Route path="/BreakoutRoom3" exact component={BreakoutRoom} />
    </Switch>
);
