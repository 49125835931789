import React from "react";
import "./style.css";

const Video = () => (
    <div className="Video">
        <iframe
            title="iframe video"
            className="Video_iframe"
            src="https://vimeo.com/event/1785694/embed"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
        />
        {/* THAT IS working video from VIMEO for testing
        <iframe
            title="iframe video"
            className="Video_iframe"
            src="https://player.vimeo.com/video/76979871?h=8272103f6e&amp;app_id=122963"
            width="480"
            height="270"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
        /> */}
    </div>
);

export default Video;
