import { createStore } from "redux";
import globalReducer from "./reducer";

const initStore = () =>
{
    const store = createStore(
        globalReducer,
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    );

    return { store };
};

const globalStore = initStore();

const {
    store: globalState
} = globalStore;

export {
    globalState,
    globalStore
};
